// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../settings'
import { objectToQueryString } from '../../../../utility/Utils'

export const apiEndpoint = `${BASE_URL}/api`

// const authAxios = axios.create({
//     baseURL: apiEndpoint,
//     headers: {
//         Authorization: `${tokenType} ${accessToken}`
//     }
// })

const authAxios = axios

export const getData = createAsyncThunk(
  'appVehicle/getData',
  async (queryParams = {}) => {
    const queryString = objectToQueryString(queryParams)

    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle?${queryString}`
      )

      return {
        data: response.data,
        allData: response.data,
        totalPages: 1,
        vehicles: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)
export const getAllVehicles = createAsyncThunk(
  'appVehicle/getAllVehicles',
  async () => {
    try {
      const response = await authAxios.get(`${apiEndpoint}/vehicle`)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const getVehicles = createAsyncThunk(
  'appVehicle/getVehicles',
  async (queryParams = { page_size: 10, page: 1 }) => {
    const queryString = objectToQueryString(queryParams)
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/list?${queryString}&sort_by=id&sort_order=desc`
      )
      return {
        data: response.data.results,
        allData: response.data.results,
        count: response.data.count,
        totalPages: Math.ceil(response.data.count / queryParams.page_size),
        vehicles: response.data.results
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getVehicle = createAsyncThunk(
  'appVehicle/getVehicle',
  async (id) => {
    try {
      const response = await authAxios.get(`${apiEndpoint}/vehicle/${id}`)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const deleteVehicle = createAsyncThunk(
  'appVehicle/deleteVehicle',
  async (id, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(`${apiEndpoint}/vehicle/${id}`)
      return id
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createVehicle = createAsyncThunk(
  'appVehicle/createVehicle',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(`${apiEndpoint}/vehicle`, data)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateVehicle = createAsyncThunk(
  'appVehicle/updateVehicle',
  async (data) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/vehicle/${data.id}`,
        data
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const getServiceTemplates = createAsyncThunk(
  'appVehicle/getServiceTemplates',
  async () => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/service_templates`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const createServiceSchedule = createAsyncThunk(
  'appVehicle/CreateServiceSchedule',
  async (data) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/service_schedules`,
        data
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const getServiceSchedules = createAsyncThunk(
  'appVehicle/GetServiceSchedules',
  async () => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/service_schedules`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const getServiceScheduleById = createAsyncThunk(
  'appVehicle/GetServiceScheduleById',
  async (id) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/service_schedules/${id}`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const updateServiceScheduleItem = createAsyncThunk(
  'appVehicle/UpdateServiceScheduleItem',
  async (data) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/vehicle/service_schedule_items/${data.id}`,
        data
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const addNewTyre = createAsyncThunk(
  'appVehicle/addNewTyre',
  async (data) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/tyres`,
        data.tyre
      )
      return {
        vehicleId: data.vehicleId,
        tyre: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const updateTyre = createAsyncThunk(
  'appVehicle/updateTyre',
  async (data) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/vehicle/${data.vehicleId}/tyres/${data.tyre.id}`,
        data.tyre
      )
      return {
        vehicleId: data.vehicleId,
        tyre: response.data
      }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteTyre = createAsyncThunk(
  'appVehicle/deleteTyre',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/tyres/${data.tyreId}?force_delete=true`
      )
      // const response = {statusText: "Hi", status: 200}
      if (response.status === 200) return data.tyreId
      else {
      }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const setDefTyre = createAsyncThunk(
  'appVehicle/setDefTyre',
  async (data) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/tyres`,
        data.tyre
      )
      return {
        vehicleId: data.vehicleId,
        tyre: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const updateDefTyre = createAsyncThunk(
  'appVehicle/updateDefTyre',
  async (data) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/vehicle/${data.vehicleId}/tyres/default`,
        data.default_tyre
      )
      return {
        vehicleId: data.vehicleId,
        default_tyre: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const addNewPlate = createAsyncThunk(
  'appVehicle/addNewPlate',
  async (data) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/plates`,
        data.plate
      )
      return {
        vehicleId: data.vehicleId,
        plate: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getPlates = createAsyncThunk(
  'appVehicle/getPlates',
  async (vehicleId) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${vehicleId}/plates`
      )
      return {
        plates: response.data,
        vehicleId
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const updatePlate = createAsyncThunk(
  'appVehicle/updatePlate',
  async (data) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/vehicle/${data.vehicleId}/plates/${data.plate.id}`,
        data.plate
      )
      return {
        vehicleId: data.vehicleId,
        plate: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const deletePlate = createAsyncThunk(
  'appVehicle/deletePlate',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/plates/${data.plateId}`
      )
      if (response.status === 200) return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const addNewDamage = createAsyncThunk(
  'appVehicle/addNewDamage',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/damages`,
        data.damage
      )
      return {
        vehicleId: data.vehicleId,
        damage: {
          ...response.data,
          damage_files: [],
          damage_images: []
        }
      }
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateDamage = createAsyncThunk(
  'appVehicle/updateDamage',
  async (data) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/vehicle/${data.vehicleId}/damages/${data.damage.id}`,
        data.damage
      )
      return {
        vehicleId: data.vehicleId,
        damage: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const deleteDamage = createAsyncThunk(
  'appVehicle/deleteDamage',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/damages/${data.damageId}`
      )
      if (response.status === 200) return damageId
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const addNewCasco = createAsyncThunk(
  'appVehicle/addNewCasco',
  async (data) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/casco`,
        data.casco
      )
      return {
        vehicleId: data.vehicleId,
        casco: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const updateCasco = createAsyncThunk(
  'appVehicle/updateCasco',
  async (data) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/vehicle/${data.vehicleId}/casco/${data.casco.id}`,
        data.casco
      )
      return {
        vehicleId: data.vehicleId,
        casco: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const deleteCasco = createAsyncThunk(
  'appVehicle/deleteCasco',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/casco/${data.cascoId}`
      )
      if (response.status === 200) return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const addNewOdometer = createAsyncThunk(
  'appVehicle/addNewOdometer',
  async (data) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/odometer`,
        data.odometer
      )
      return {
        vehicleId: data.vehicleId,
        odometer: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const deleteOdometer = createAsyncThunk(
  'appVehicle/deleteOdometer',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/odometer/${data.odometerId}`
      )
      if (response.status === 200) return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const addNewService = createAsyncThunk(
  'appVehicle/addNewService',
  async (data) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/service`,
        data.service
      )
      return {
        vehicleId: data.vehicleId,
        service: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)


export const updateService = createAsyncThunk(
  'appVehicle/updateService',
  async (data) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/vehicle/${data.vehicleId}/service/${data.service.id}`,
        data.service
      )
      return {
        vehicleId: data.vehicleId,
        service: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const deleteService = createAsyncThunk(
  'appVehicle/deleteService',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/service/${data.serviceId}`
      )

      return data
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const addNewNote = createAsyncThunk(
  'appVehicle/addNewNote',
  async (data) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/notes`,
        data.note
      )
      return {
        vehicleId: data.vehicleId,
        note: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const deleteNote = createAsyncThunk(
  'appVehicle/deleteNote',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/notes/${data.noteId}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getRegistrations = createAsyncThunk(
  'appVehicle/getRegistration',
  async ({ vehicleId, plateId }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${vehicleId}/plates/${plateId}/registration`
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getPenalties = async (vehicleId) => {
  try {
    const response = await authAxios.get(
      `${apiEndpoint}/vehicle/${vehicleId}/penalty`
    )

    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const addNewPenalty = async (data) => {
  try {
    const response = await authAxios.post(
      `${apiEndpoint}/vehicle/${data.vehicleId}/penalty`,
      data.penalty
    )

    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const updatePenalty = async (data) => {
  try {
    const response = await authAxios.put(
      `${apiEndpoint}/vehicle/${data.vehicleId}/penalty/${data.penaltyId}`,
      data.penalty
    )

    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getPenaltySubclass = async (subclassId) => {
  try {
    const respSub = await authAxios.get(
      `${apiEndpoint}/manager/penalty_subclass/${subclassId}`
    )
    const respCls = await authAxios.get(
      `${apiEndpoint}/manager/penalty_class/${respSub.data.penalty_class}`
    )

    return {
      penaltySubclass: respSub.data.name,
      penaltyClass: respCls.data.name
    }
  } catch (error) {
    console.log(error)
  }
}

export const getSubclasses = async () => {
  try {
    const response = await authAxios.get(
      `${apiEndpoint}/manager/penalty_subclass`
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getClasses = async () => {
  try {
    const response = await authAxios.get(`${apiEndpoint}/manager/penalty_class`)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const addNewPenaltyReaction = async (data) => {
  try {
    const response = await authAxios.post(
      `${apiEndpoint}/vehicle/${data.vehicleId}/penalty/${data.penaltyId}/reaction`,
      data.reaction
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getPenaltyReactions = async (vehicleId, penaltyId) => {
  try {
    const response = await authAxios.get(
      `${apiEndpoint}/vehicle/${vehicleId}/penalty/${penaltyId}/reaction`
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const uploadPenaltyFile = async (data) => {
  try {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    const response = await authAxios.post(
      `${apiEndpoint}/vehicle/${data.vehicleId}/penalty/${data.penaltyId}/files`,
      data.file,
      config
    )

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getPenaltyFiles = async (vehicleId, penaltyId) => {
  try {
    const response = await authAxios.get(
      `${apiEndpoint}/vehicle/${vehicleId}/penalty/${penaltyId}/files`
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const addNewRegistration = createAsyncThunk(
  'appUsers/addNewRegistration',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/plates/${data.plateId}/registration`,
        data.registration
      )
      return {...response.data, plate_id: data.plateId}
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)
export const deletePenalty = async ({ vehicleId, penaltyId }) => {
  try {
    const response = await authAxios.delete(
      `${apiEndpoint}/vehicle/${vehicleId}/penalty/${penaltyId}`
    )
    return penaltyId
  } catch (error) {
    console.log(error)
  }
}

/*export const deletePenalty = createAsyncThunk(
  'appUsers/deletePenalty',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/penalty/${data.penaltyId}`
      )
      return data.penaltyId
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)*/
export const renewRegistration = createAsyncThunk(
  'appUsers/renewRegistration',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/plates/${data.plateId}/registration/renew/`,
        data.submitData
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const addNewRegistrationFile = createAsyncThunk(
  'appUsers/addNewRegistrationFile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/plates/${data.plateId}/registration/${data.id}/file`,
        data.file
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getRegistrationFiles = createAsyncThunk(
  'appUsers/getRegistrationFiles',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${data.vehicleId}/plates/${data.plateId}/registration/${data.id}/file`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteRegistrationFiles = createAsyncThunk(
  'appUsers/deleteRegistrationFiles',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/plates/${data.plateId}/registration/${data.registrationId}/file/${data.id}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const uploadRegistrationImage = createAsyncThunk(
  'appVehicle/uploadRegistrationImage',
  async (data) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/plates/${data.plateId}/registration/${data.registrationId}/images`,
        data.file,
        config
      )

      return {
        registrationImage: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getRegistrationImages = createAsyncThunk(
  'appUsers/getRegistrationImages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${data.vehicleId}/plates/${data.plateId}/registration/${data.id}/images`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteRegistrationImages = createAsyncThunk(
  'appUsers/deleteRegistrationImages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/plates/${data.plateId}/registration/${data.registrationId}/images/${data.id}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateRegistration = createAsyncThunk(
  'appUsers/updateRegistration',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/vehicle/${data.vehicleId}/plates/${data.plateId}/registration/${data.registration.id}`,
        data.registration
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const addNewServiceFile = createAsyncThunk(
  'appUsers/addNewServiceFile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/service/${data.serviceId}/files`,
        data.file
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getServiceFiles = createAsyncThunk(
  'appUsers/getServiceFiles',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${data.vehicleId}/service/${data.serviceId}/files`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteServiceFiles = createAsyncThunk(
  'appUsers/deleteServiceFiles',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/service/${data.serviceId}/files/${data.id}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)
export const uploadServiceImage = createAsyncThunk(
  'appVehicle/uploadServiceImage',
  async (data) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/service/${data.serviceId}/images`,
        data.file,
        config
      )

      return {
        serviceImage: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getServiceImages = createAsyncThunk(
  'appUsers/getServiceImages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${data.vehicleId}/service/${data.serviceId}/images`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteServiceImages = createAsyncThunk(
  'appUsers/deleteServiceImages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/service/${data.serviceId}/image/${data.id}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)
export const addNewTyresFile = createAsyncThunk(
  'appUsers/addNewTyresFile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/tyres/${data.tyresetId}/files`,
        data.file
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getTyresFiles = createAsyncThunk(
  'appUsers/getTyresFiles',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${data.vehicleId}/tyres/${data.tyresetId}/files`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteTyresFiles = createAsyncThunk(
  'appUsers/deleteTyresFiles',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/tyres/${data.tyresetId}/files/${data.id}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const uploadTyresImage = createAsyncThunk(
  'appVehicle/uploadTyresImage',
  async (data) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/tyres/${data.tyresetId}/images`,
        data.file,
        config
      )

      return {
        tyresImage: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getTyresImages = createAsyncThunk(
  'appUsers/getTyresImages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${data.vehicleId}/tyres/${data.tyresetId}/images`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteTyresImages = createAsyncThunk(
  'appUsers/deleteTyresImages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/tyres/${data.tyresetId}/images/${data.id}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const addNewCascoFile = createAsyncThunk(
  'appUsers/addNewCascoFile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/casco/${data.cascoId}/files`,
        data.file
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getCascoFiles = createAsyncThunk(
  'appUsers/getCascoFiles',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${data.vehicleId}/casco/${data.cascoId}/files`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteCascoFiles = createAsyncThunk(
  'appUsers/deleteCascoFiles',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/casco/${data.cascoId}/files/${data.id}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const uploadCascoImage = createAsyncThunk(
  'appVehicle/uploadCascoImage',
  async (data) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/casco/${data.cascoId}/images`,
        data.file,
        config
      )

      return {
        cascoImage: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getCascoImages = createAsyncThunk(
  'appUsers/getCascoImages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${data.vehicleId}/casco/${data.cascoId}/images`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteCascoImages = createAsyncThunk(
  'appUsers/deleteCascoImages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/casco/${data.cascoId}/images/${data.id}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)
export const addNewFinanceFile = createAsyncThunk(
  'appUsers/addNewFinanceFile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/finance/source/${data.sourceId}/files`,
        data.file
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getFinanceFile = createAsyncThunk(
  'appUsers/getFinanceFile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/finance/source/${data.sourceId}/files`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteFinanceFiles = createAsyncThunk(
  'appUsers/deleteFinanceFiles',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/finance/source/${data.sourceId}/files/${data.id}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)
export const uploadFinanceImage = createAsyncThunk(
  'appVehicle/uploadFinanceImage',
  async (data) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      const response = await authAxios.post(
        `${apiEndpoint}/finance/source/${data.sourceId}/images`,
        data.file,
        config
      )

      return {
        sourceImage: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getFinanceImages = createAsyncThunk(
  'appUsers/getFinanceImages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/finance/source/${data.sourceId}/images`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteFinanceImages = createAsyncThunk(
  'appUsers/deleteFinanceImages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/finance/source/${data.sourceId}/images/${data.id}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const addNewVehicleFile = createAsyncThunk(
  'appUsers/addNewVehicleFile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/files`,
        data.file
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getVehicleFile = createAsyncThunk(
  'appUsers/getVehicleFile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${data.vehicleId}/files`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteVehicleFiles = createAsyncThunk(
  'appUsers/deleteVehicleFiles',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/files/${data.id}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)
export const uploadVehicleImage = createAsyncThunk(
  'appVehicle/uploadVehicleImage',
  async (data) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/images`,
        data.file,
        config
      )

      return {
        vehicleImage: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getVehicleImages = createAsyncThunk(
  'appUsers/getVehicleImages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${data.vehicleId}/images`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteVehicleImages = createAsyncThunk(
  'appUsers/deleteVehicleImages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/images/${data.id}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const uploadDmgFile = createAsyncThunk(
  'appVehicle/uploadDmgFile',
  async (data) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/damages/${data.dmgId}/files`,
        data.file,
        config
      )

      return {
        vehicleId: data.vehicleId,
        dmgId: data.dmgId,
        dmgFile: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const uploadDmgImage = createAsyncThunk(
  'appVehicle/uploadDmgImage',
  async (data) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${data.vehicleId}/damages/${data.dmgId}/images`,
        data.file,
        config
      )

      return {
        vehicleId: data.vehicleId,
        dmgId: data.dmgId,
        dmgImage: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const deleteDmgFile = createAsyncThunk(
  'appVehicle/deleteDmgFile',
  async (data) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/damages/${data.dmgId}/files/${data.fileId}`
      )

      return data
    } catch (error) {
      console.log(error)
    }
  }
)

export const deleteDmgImage = createAsyncThunk(
  'appVehicle/deleteDmgImage',
  async (data) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${data.vehicleId}/damages/${data.dmgId}/images/${data.imgId}`
      )

      return data
    } catch (error) {
      console.log(error)
    }
  }
)

/*export const deleteRegistration = async ({
  vehicleId,
  plateId,
  registrationId
}) => {
  try {
    const response = await authAxios.delete(
      `${apiEndpoint}/vehicle/${vehicleId}/plates/${plateId}/registration/${registrationId}`
    )

    return registrationId
  } catch (error) {
    console.log(error)
  }
}*/

export const deleteRegistration = createAsyncThunk(
  'appVehicle/deleteRegistration',
  async ({ vehicleId, plateId, registrationId }) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/vehicle/${vehicleId}/plates/${plateId}/registration/${registrationId}`
      )
      return {plateId, registrationId}
    } catch (error) {
      console.log(error)
    }
  }
)
export const acknowledgeRemainder = createAsyncThunk(
  'appVehicle/acknowledgeRemainder',
  async ({ vehicleId, remainderId }, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/remainder/acknowledge/${remainderId}`
      )

      return {
        vehicleId,
        remainderId
      }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createNewReminder = createAsyncThunk(
  'appVehicle/createNewReminder',
  async (data) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/remainder/vehicle/${data.vehicle}`,
        data
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getVehicleFinancing = createAsyncThunk(
  'appVehicle/getVehicleFinancing',
  async (vehicle_id) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/finance/source/vehicle/${vehicle_id}`
      )
      return { data: response.data[0], vehicleId: vehicle_id }
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)
export const deleteVehicleFinancing = createAsyncThunk(
  'appVehicle/deleteVehicleFinancing',
  async (source_id) => {
    try {
      const response = await authAxios.delete(
        `${apiEndpoint}/finance/source/${source_id}`
      )
      return source_id
    } catch (error) {
      console.log(error)
      throw error
    } 
  }
)

export const createSource = createAsyncThunk(
  'appVehicle/createSource',
  async ({ data, vehicleId }, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/finance/source/vehicle/${vehicleId}`,
        data
      )

      return {
        vehicleId: data.vehicleId,
        data: response.data
      }
    } catch (error) {
      console.log(error)
      return rejectWithValue({ message: 'Failed to create source.' })
    }
  }
)

export const updateSource = createAsyncThunk(
  'appVehicle/updateSource',
  async ({ data, sourceId }, { rejectWithValue }) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/finance/source/${sourceId}`,
        data
      )

      return {
        sourceId: data.sourceId,
        data: response.data
      }
    } catch (error) {
      console.log(error)
      return rejectWithValue({ message: 'Failed to update source.' })
    }
  }
)
export const updateSourceRetro = createAsyncThunk(
  'appVehicle/updateSourceRetro',
  async ({ data, repaymentId, sourceId }, { rejectWithValue }) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/finance/source/${sourceId}/repayment/update_installments`,
        data
      )

      return {
        sourceId: data.sourceId,
        data: response.data
      }
    } catch (error) {
      console.log(error)
      return rejectWithValue({ message: 'Failed to update source.' })
    }
  }
)
export const updateSourceRepayment = createAsyncThunk(
  'appVehicle/updateSourceRepayment',
  async ({ sourceId, repaymentId, data }, { rejectWithValue }) => {
    try {
      const response = await authAxios.put(
        `${apiEndpoint}/finance/source/${sourceId}/repayment/${repaymentId}`,
        data
      )

      return {
        data: response.data
      }
    } catch (error) {
      console.log(error)
      return rejectWithValue({ message: 'Failed to update source.' })
    }
  }
)
export const listLifecycleEvents = createAsyncThunk(
  'appVehicle/listLifecycleEvents',
  async ({ vehicleId, tyreId}, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${vehicleId}/tyres/${tyreId}/lifecycle-events?page=1&page_size=20`
      )

      return response.data?.results
    } catch (error) {
      console.log(error)
      return rejectWithValue({ message: 'Failed to create source.' })
    }
  }
)

export const createLifecycleEvent = createAsyncThunk(
  'appVehicle/createLifecycleEvent',
  async ({data, tyreId, vehicleId}) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${vehicleId}/tyres/${tyreId}/lifecycle-events`,
        data
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const listManagementEvents = createAsyncThunk(
  'appVehicle/listManagementEvents',
  async ({ vehicleId, tyreId}, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${vehicleId}/tyres/${tyreId}/management-events?page=1&page_size=20`
      )

      return response.data?.results
    } catch (error) {
      console.log(error)
      return rejectWithValue({ message: 'Failed to create source.' })
    }
  }
)
export const createManagementEvent = createAsyncThunk(
  'appVehicle/createManagementEvent',
  async ({ data, vehicleId, tyreId }, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${vehicleId}/tyres/${tyreId}/management-events`,
        data
      )

      return response.data
    } catch (error) {
      console.log(error)
      return rejectWithValue({ message: 'Failed to create source.' })
    }
  }
)

export const listTyreServices = createAsyncThunk(
  'appVehicle/listTyreServices',
  async ({ data, vehicleId, tyreId }, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${vehicleId}/tyres/${tyreId}/services`,
        data
      )

      return response.data
    } catch (error) {
      console.log(error)
      return rejectWithValue({ message: 'Failed to create source.' })
    }
  }
)

export const createTyreService = createAsyncThunk(
  'appVehicle/createTyreService',
  async ({ data, vehicleId, tyreId }, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `${apiEndpoint}/vehicle/${vehicleId}/tyres/${tyreId}/services`,
        data
      )

      return response.data
    } catch (error) {
      console.log(error)
      return rejectWithValue({ message: 'Failed to create service.' })
    }
  }
)
export const getVehicleFiles = createAsyncThunk(
  'appVehicle/getVehicleFiles',
  async ({ vehicleId }) => {
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/vehicle/${vehicleId}/documents`
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)
export const getLongTermContracts = createAsyncThunk(
  'appVehicle/getLongTermContracts',
  async (queryParams = {}) => {
    const queryString = objectToQueryString(queryParams)

    try {
      const response = await authAxios.get(
        `${apiEndpoint}/business/longtermcontract?${queryString}`
      )

      return response.data
    } catch (error) {
      console.log(error)
      return []
    }
  }
)
export const appVehicleSlice = createSlice({
  name: 'appVehicle',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedVehicle: null,
    plates: [],
    registrations: [],
    allVehicleFiles: [],
    vehicleFinancing: [],
    registrationDocuments: { registrationFiles: [], registrationImages: [] },
    serviceDocuments: { serviceFiles: [], serviceImages: [] },
    cascoDocuments: { cascoFiles: [], cascoImages: [] },
    tyresDocuments: { tyresFiles: [], tyresImages: [] },
    tyreLifeCycle: [],
    tyreManagement: [],
    tyreServices: [],
    vehicleDocuments: { vehicleFiles: [], vehicleImages: [] },
    financeDocuments: { financeFiles: [], financeImages: [] },
    serviceTemplates: [],
    serviceSchedules: [],
    longTermContracts: [],
    isLoading: false,
    isFinanceLoading: false,
    isRegistrationLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        try {
          state.data = action.payload.data
          state.allData = action.payload.allData
          state.total = action.payload.totalPages
          state.params = action.payload.params
        } catch (error) {
          console.log(error)
        }
        state.isLoading = false
      })
      .addCase(getData.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(getData.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getAllVehicles.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getVehicles.fulfilled, (state, action) => {
        try {
          state.data = action.payload.data
          state.allData = action.payload.allData
          state.total = action.payload.totalPages
          state.count = action.payload.count
          state.params = action.payload.params
        } catch (error) {
          console.log(error)
        }
        state.isLoading = false
      })
      .addCase(getVehicles.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(getVehicles.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getVehicle.fulfilled, (state, action) => {
        try {
          const vehicleIdx = state?.allData?.findIndex(
            (v) => v.id === action.payload.id
          )
          if (vehicleIdx !== -1) {
            state.allData[vehicleIdx] = action.payload
          } else {
            state.allData.push(action.payload)
          }
          state.selectedVehicle = action.payload
        } catch (error) {
          console.log(error)
        } finally {
          state.isLoading = false
        }
      })
      .addCase(getVehicle.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteVehicle.fulfilled, (state, action) => {
        try {
          const filtered = state.allData.filter(
            (vehicle) => vehicle.id !== action.payload
          )
          state.allData = [...filtered]
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(updateVehicle.fulfilled, (state, action) => {
        state.selectedVehicle = { ...state.selectedVehicle, ...action.payload }
      })
      .addCase(getServiceTemplates.fulfilled, (state, action) => {
        state.serviceTemplates = action.payload
      })
      .addCase(createServiceSchedule.fulfilled, (state, action) => {
        state.serviceSchedules.push(action.payload)
      })
      .addCase(getServiceSchedules.fulfilled, (state, action) => {
        state.serviceSchedules = action.payload
      })
      .addCase(getServiceScheduleById.fulfilled, (state, action) => {
        //state.serviceSchedules = action.payload
      })
      .addCase(updateServiceScheduleItem.fulfilled, (state, action) => {
        const idX = state.serviceSchedules.findIndex(
          (schedule) => schedule.items.some((item) => item.id === action.payload.id)
        )
        const idY = state.serviceSchedules[idX]?.items.findIndex(
          (item) => item.catalog_number === action.payload.catalog_number
        )
        state.serviceSchedules[idX].items[idY] = action.payload
      })
      .addCase(getVehicleFinancing.pending, (state, action) => {
        state.isFinanceLoading = true
      })
      .addCase(getVehicleFinancing.fulfilled, (state, action) => {
        state.isFinanceLoading = false
        state.vehicleFinancing = action.payload.data
      })
      .addCase(deleteVehicleFinancing.pending, (state, action) => {
        state.isFinanceLoading = true
      })
      .addCase(deleteVehicleFinancing.fulfilled, (state, action) => {
        state.isFinanceLoading = false
        state.vehicleFinancing = []
      })
      .addCase(createSource.fulfilled, (state, action) => {
        const idx = state.allData.findIndex(
          (vehicle) => vehicle.id === action.payload.vehicleId
        )
        state.allData[idx] = action.payload.data
      })
      .addCase(updateSource.fulfilled, (state, action) => {
        const idx = state.allData.findIndex(
          (vehicle) => vehicle.id === action.payload.sourceId
        )
        state.allData[idx] = action.payload.data
      })
      .addCase(listLifecycleEvents.fulfilled, (state, action) => {
        state.tyreLifeCycle = action.payload
      })
      .addCase(createLifecycleEvent.fulfilled, (state, action) => {
        if (action.payload) {
          state.tyreLifeCycle = [...state.tyreLifeCycle, action.payload].sort((a, b) => new Date(a.performed_date) - new Date(b.performed_date))
        }
      })
      .addCase(listManagementEvents.fulfilled, (state, action) => {
        state.tyreManagement = action.payload
      })
      .addCase(createManagementEvent.fulfilled, (state, action) => {
        if (action.payload) {
          state.tyreManagement = [...state.tyreManagement, action.payload].sort((a, b) => new Date(a.performed_date) - new Date(b.performed_date))
        }
      })
      .addCase(listTyreServices.fulfilled, (state, action) => {
        state.tyreServices = action.payload
      })
      .addCase(createTyreService.fulfilled, (state, action) => {
        state.tyreServices = [...state.tyreServices, action.payload]
      })
      .addCase(addNewTyre.fulfilled, (state, action) => {
        try {
          state.selectedVehicle.tyres.push(action.payload.tyre)
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(updateTyre.fulfilled, (state, action) => {
        try {
          const vehicleIdx = state.allData.findIndex(
            (v) => v.id === action.payload.vehicleId
          )
          const tyreIdx = state.selectedVehicle.tyres.findIndex(
            (t) => t.id === action.payload.tyre.id
          )
          state.selectedVehicle.tyres[tyreIdx] = action.payload.tyre
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(deleteTyre.fulfilled, (state, action) => {
        const tyreIdx = state.selectedVehicle.tyres.findIndex(
          (t) => t.id === action.payload
        )
        state.selectedVehicle.tyres.splice(tyreIdx, 1)
      })
      .addCase(updateDefTyre.fulfilled, (state, action) => {
        try {
          state.selectedVehicle.default_tyre = action.payload.default_tyre
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(addNewPlate.fulfilled, (state, action) => {
        try {
          const isActive = action.payload.plate.active

          if (isActive) {
            state.selectedVehicle.plates = state.selectedVehicle.plates.map(
              (plate) => ({ ...plate, active: false })
            )
          }

          state.selectedVehicle.plates.push(action.payload.plate)
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(updatePlate.fulfilled, (state, action) => {
        try {
          const plateIdx = state.selectedVehicle.plates.findIndex(
            (p) => p.id === action.payload.plate.id
          )

          state.selectedVehicle.plates[plateIdx] = action.payload.plate
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(deletePlate.fulfilled, (state, action) => {
        const plateIdx = state.selectedVehicle.plates.findIndex(
          (p) => p.id === action.payload.plateId
        )
        state.selectedVehicle.plates.splice(plateIdx, 1)
      })
      .addCase(addNewDamage.fulfilled, (state, action) => {
        try {
          state.selectedVehicle.damages.push(action.payload.damage)
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(updateDamage.fulfilled, (state, action) => {
        try {
          const damageIdx = state.selectedVehicle.damages.findIndex(
            (d) => d.id === action.payload.damage.id
          )
          const newValue = {
            ...state.selectedVehicle.damages[damageIdx],
            ...action.payload.damage
          }
          state.selectedVehicle.damages[damageIdx] = newValue
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(deleteDamage.fulfilled, (state, action) => {
        const damageIdx = state.selectedVehicle.damages.findIndex(
          (d) => d.id === action.payload
        )
        state.selectedVehicle.damages.splice(damageIdx, 1)
      })
      .addCase(addNewCasco.fulfilled, (state, action) => {
        try {
          state.selectedVehicle.casco.push(action.payload.casco)
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(updateCasco.fulfilled, (state, action) => {
        try {
          const cascoIdx = state.selectedVehicle.casco.findIndex(
            (c) => c.id === action.payload.casco.id
          )
          state.selectedVehicle.casco[cascoIdx] = action.payload.casco
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(deleteCasco.fulfilled, (state, action) => {
        const cascoIdx = state.selectedVehicle.casco.findIndex(
          (c) => c.id === action.payload.cascoId
        )
        state.selectedVehicle.casco.splice(cascoIdx, 1)
      })
      .addCase(addNewOdometer.fulfilled, (state, action) => {
        try {
          state.selectedVehicle.odometers.push(action.payload.odometer)
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(deleteOdometer.fulfilled, (state, action) => {
        const filtered = state.selectedVehicle.odometers.filter(
          (o) => o.id !== action.payload.odometerId
        )
        state.selectedVehicle.odometers = [...filtered]
      })
      .addCase(addNewService.fulfilled, (state, action) => {
        try {
          state.selectedVehicle.service.push(action.payload.service)
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(updateService.fulfilled, (state, action) => {
        try {
          const serviceIdx = state.selectedVehicle.service.findIndex(
            (c) => c.id === action.payload.service.id
          )
          state.selectedVehicle.service[serviceIdx] = action.payload.service
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        const serviceIdx = state.selectedVehicle.service.findIndex(
          (c) => c.id === action.payload.serviceId
        )
        state.selectedVehicle.service.splice(serviceIdx, 1)
      })
      .addCase(addNewNote.fulfilled, (state, action) => {
        try {
          state.selectedVehicle.notes.push(action.payload.note)
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        try {
          const filtered = state.selectedVehicle.notes.filter(
            (n) => n.id !== action.payload.noteId
          )
          state.selectedVehicle.notes = [...filtered]
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(acknowledgeRemainder.fulfilled, (state, action) => {
        const remainderIdx = state.selectedVehicle.vehicle_remainders.findIndex(
          (r) => r.id === action.payload.remainderId
        )
        state.selectedVehicle.vehicle_remainders[
          remainderIdx
        ].acknowledge = true
      })
      .addCase(createNewReminder.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(createNewReminder.fulfilled, (state, action) => {
        state.isLoading = false
        state.selectedVehicle.vehicle_remainders.push(action.payload)
      })
      .addCase(deleteDmgFile.fulfilled, (state, action) => {
        const dmgIdx = state.selectedVehicle.damages.findIndex(
          (d) => d.id === action.payload.dmgId
        )
        const filtered = state.selectedVehicle.damages[
          dmgIdx
        ].damage_files.filter((i) => i.id !== action.payload.fileId)
        state.selectedVehicle.damages[dmgIdx].damage_files = [...filtered]
      })
      .addCase(deleteDmgImage.fulfilled, (state, action) => {
        const dmgIdx = state.selectedVehicle.damages.findIndex(
          (d) => d.id === action.payload.dmgId
        )
        const filtered = state.selectedVehicle.damages[
          dmgIdx
        ].damage_images.filter((i) => i.id !== action.payload.imgId)
        state.selectedVehicle.damages[dmgIdx].damage_images = [...filtered]
      })
      .addCase(uploadDmgFile.fulfilled, (state, action) => {
        const dmgIdx = state.selectedVehicle.damages.findIndex(
          (d) => d.id === action.payload.dmgId
        )
        const newValue = [
          ...state.selectedVehicle.damages[dmgIdx].damage_files,
          action.payload.dmgFile
        ]
        state.selectedVehicle.damages[dmgIdx].damage_files = newValue
      })
      .addCase(uploadDmgImage.fulfilled, (state, action) => {
        const dmgIdx = state.selectedVehicle.damages.findIndex(
          (d) => d.id === action.payload.dmgId
        )
        const newValue = [
          ...state.selectedVehicle.damages[dmgIdx].damage_images,
          action.payload.dmgImage
        ]
        state.selectedVehicle.damages[dmgIdx].damage_images = newValue
      })
      .addCase(getPlates.fulfilled, (state, action) => {
        state.plates = action?.payload?.plates
      })
      .addCase(getRegistrations.fulfilled, (state, action) => {
        state.registrations = action?.payload
      })
      .addCase(addNewRegistration.fulfilled, (state, action) => {
        const { plate_id, ...registrationData } = action.payload
        const plateIdx = state.selectedVehicle.plates.findIndex(plate => plate.id === plate_id)
        if (plateIdx !== -1) {
          state.selectedVehicle.plates[plateIdx].registrations.push(registrationData)
        }
      })
      .addCase(addNewRegistrationFile.fulfilled, (state, action) => {
        state.registrationDocuments.registrationFiles.push(action?.payload)
      })
      .addCase(updateRegistration.fulfilled, (state, action) => {
        const updatedRegistration = action.payload
        const registrationIdx = state.registrations.findIndex(
          (reg) => reg.id === updatedRegistration.registration.id
        )
        if (registrationIdx !== -1) {
          state.registrations[registrationIdx] =
            updatedRegistration.registration
        }
      })
      .addCase(getRegistrationFiles.pending, (state, action) => {
        state.isRegistrationLoading = true
      })
      .addCase(getRegistrationFiles.fulfilled, (state, action) => {
        state.registrationDocuments.registrationFiles = action.payload
        state.isRegistrationLoading = false
      })
      .addCase(deleteRegistration.fulfilled, (state, action) => {
        const plateIdx = state.selectedVehicle.plates.findIndex(
          (plate) => plate.id === action.payload.plateId
        )
        const registrationIdx = state.selectedVehicle.plates[plateIdx].registrations.findIndex(
          (reg) => reg.id === action.payload.registrationId
        )
        state.selectedVehicle.plates[plateIdx].registrations.splice(
          registrationIdx,
          1
        )
      })
      .addCase(deleteRegistrationFiles.fulfilled, (state, action) => {
        const fileId = action.payload.id
        state.registrationDocuments.registrationFiles =
          state.registrationDocuments.registrationFiles.filter(
            (file) => file.id !== fileId
          )
      })
      .addCase(getRegistrationImages.pending, (state, action) => {
        state.isRegistrationLoading = true
      })
      .addCase(getRegistrationImages.fulfilled, (state, action) => {
        state.registrationDocuments.registrationImages = action.payload
        state.isRegistrationLoading = false
      })
      .addCase(deleteRegistrationImages.fulfilled, (state, action) => {
        const imageId = action.payload.id
        state.registrationDocuments.registrationImages =
          state.registrationDocuments.registrationImages.filter(
            (image) => image.id !== imageId
          )
      })
      .addCase(uploadRegistrationImage.fulfilled, (state, action) => {
        state.registrationDocuments.registrationImages.push(
          action?.payload?.registrationImage
        )
      })
      .addCase(getServiceFiles.fulfilled, (state, action) => {
        state.serviceDocuments.serviceFiles = action.payload
      })
      .addCase(addNewServiceFile.fulfilled, (state, action) => {
        state.serviceDocuments.serviceFiles.push(action?.payload)
      })
      .addCase(deleteServiceFiles.fulfilled, (state, action) => {
        const fileId = action.payload.id
        state.serviceDocuments.serviceFiles =
          state.serviceDocuments.serviceFiles.filter(
            (file) => file.id !== fileId
          )
      })
      .addCase(getServiceImages.fulfilled, (state, action) => {
        state.serviceDocuments.serviceImages = action.payload
      })
      .addCase(deleteServiceImages.fulfilled, (state, action) => {
        const imageId = action.payload.id
        state.serviceDocuments.serviceImages =
          state.serviceDocuments.serviceImages.filter(
            (image) => image.id !== imageId
          )
      })
      .addCase(uploadServiceImage.fulfilled, (state, action) => {
        state.serviceDocuments.serviceImages.push(action?.payload?.serviceImage)
      })
      .addCase(getCascoFiles.fulfilled, (state, action) => {
        state.cascoDocuments.cascoFiles = action.payload
      })
      .addCase(addNewCascoFile.fulfilled, (state, action) => {
        state.cascoDocuments.cascoFiles.push(action?.payload)
      })
      .addCase(deleteCascoFiles.fulfilled, (state, action) => {
        const fileId = action.payload.id
        state.cascoDocuments.cascoFiles =
          state.cascoDocuments.cascoFiles.filter((file) => file.id !== fileId)
      })
      .addCase(getCascoImages.fulfilled, (state, action) => {
        state.cascoDocuments.cascoImages = action.payload
      })
      .addCase(deleteCascoImages.fulfilled, (state, action) => {
        const imageId = action.payload.id
        state.cascoDocuments.cascoImages =
          state.cascoDocuments.cascoImages.filter(
            (image) => image.id !== imageId
          )
      })
      .addCase(uploadCascoImage.fulfilled, (state, action) => {
        state.cascoDocuments.cascoImages.push(action?.payload?.cascoImage)
      })
      .addCase(getTyresFiles.fulfilled, (state, action) => {
        state.tyresDocuments.tyresFiles = action.payload
      })
      .addCase(addNewTyresFile.fulfilled, (state, action) => {
        state.tyresDocuments.tyresFiles.push(action?.payload)
      })
      .addCase(deleteTyresFiles.fulfilled, (state, action) => {
        const fileId = action.payload.id
        state.tyresDocuments.tyresFiles =
          state.tyresDocuments.tyresFiles.filter((file) => file.id !== fileId)
      })
      .addCase(getTyresImages.fulfilled, (state, action) => {
        state.tyresDocuments.tyresImages = action.payload
      })
      .addCase(deleteTyresImages.fulfilled, (state, action) => {
        const imageId = action.payload.id
        state.tyresDocuments.tyresImages =
          state.tyresDocuments.tyresImages.filter(
            (image) => image.id !== imageId
          )
      })
      .addCase(uploadTyresImage.fulfilled, (state, action) => {
        state.tyresDocuments.tyresImages.push(action?.payload?.tyresImage)
      })
      .addCase(getVehicleFiles.fulfilled, (state, action) => {
        state.allVehicleFiles = action.payload
      })
      .addCase(getVehicleFile.fulfilled, (state, action) => {
        state.vehicleDocuments.vehicleFiles = action.payload
      })
      .addCase(addNewVehicleFile.fulfilled, (state, action) => {
        state.vehicleDocuments.vehicleFiles.push(action?.payload)
      })
      .addCase(deleteVehicleFiles.fulfilled, (state, action) => {
        const fileId = action.payload.id
        state.vehicleDocuments.vehicleFiles =
          state.vehicleDocuments.vehicleFiles.filter(
            (file) => file.id !== fileId
          )
      })
      .addCase(getVehicleImages.fulfilled, (state, action) => {
        state.vehicleDocuments.vehicleImages = action.payload
      })
      .addCase(deleteVehicleImages.fulfilled, (state, action) => {
        const imageId = action.payload.id
        state.vehicleDocuments.vehicleImages =
          state.vehicleDocuments.vehicleImages.filter(
            (image) => image.id !== imageId
          )
      })
      .addCase(uploadVehicleImage.fulfilled, (state, action) => {
        state.vehicleDocuments.vehicleImages.push(action?.payload?.vehicleImage)
      })
      .addCase(getFinanceFile.fulfilled, (state, action) => {
        state.financeDocuments.financeFiles = action.payload
      })
      .addCase(addNewFinanceFile.fulfilled, (state, action) => {
        state.financeDocuments.financeFiles.push(action?.payload)
      })
      .addCase(deleteFinanceFiles.fulfilled, (state, action) => {
        const fileId = action.payload.id
        state.financeDocuments.financeFiles =
          state.financeDocuments.financeFiles.filter(
            (file) => file.id !== fileId
          )
      })
      .addCase(getFinanceImages.fulfilled, (state, action) => {
        state.financeDocuments.financeImages = action.payload
      })
      .addCase(deleteFinanceImages.fulfilled, (state, action) => {
        const imageId = action.payload.id
        state.financeDocuments.financeImages =
          state.financeDocuments.financeImages.filter(
            (image) => image.id !== imageId
          )
      })
      .addCase(uploadFinanceImage.fulfilled, (state, action) => {
        state.financeDocuments.financeImages.push(action?.payload?.sourceImage)
      })
      .addCase(getLongTermContracts.fulfilled, (state, action) => {
        state.longTermContracts = action.payload.results
      })
  }
})

export default appVehicleSlice.reducer
