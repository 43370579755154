// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../settings'
import { objectToQueryString } from '../../../utility/Utils'
export const apiEndpoint = `${BASE_URL}/api`

const authAxios = axios

  export const getPenalties = createAsyncThunk(
    'appDamages/getPenalties',
    async (queryParams = { page_size: 10, page: 1 }) => {
      const queryString = objectToQueryString(queryParams)
      try {
        const response = await authAxios.get(
          `${apiEndpoint}/vehicle/penalty?${queryString}`
        )
        return {
          data: response.data.results,
          allData: response.data.results,
          count: response.data.count,
          totalPages: Math.ceil(response.data.count / queryParams.page_size),
          vehicles: response.data.results
        }
      } catch (error) {
        console.log(error)
      }
    }
  )
export const appDamagesSlice = createSlice({
    name: 'appDamages',
    initialState: {
        data: [],
        isLoading: false,
        allData: [],
        total: 0,
        params: {},
        count: 0

    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getPenalties.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getPenalties.fulfilled, (state, action) => {
                try {
                    state.data = action.payload.data
                    state.allData = action.payload.allData
                    state.total = action.payload.totalPages
                    state.count = action.payload.count
                    state.params = action.payload.params
                    state.isLoading = false
                } catch (error) {
                    console.log(error)
                }
                state.isLoading = false
            })
    }
})

export default appDamagesSlice.reducer