import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { objectToQueryString } from '../../../utility/Utils'
// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../settings'
export const apiEndpoint = `${BASE_URL}/api`

const INITIAL_STATE = {
  // GENERAL
  allcontracts: [],
  contract: null,
  isLoading: false,
  error: null,
  data: [],
  allData: [],
  total: 0,
  count: 0,
  params: {},
  driverAssignments: []
}

export const getAssignmentsByVehicleId = createAsyncThunk(
  'appVehicle/getDriversAssignments',
  async (vehicleId) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/business/assignment/vehicle/${vehicleId}`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const getLongTermContractsV2 = createAsyncThunk(
  'contracts/getLongTermContractsV2',
  async (queryParams = { page_size: 10, page: 1 }) => {
    const queryString = objectToQueryString(queryParams)
    try {
      const response = await axios.get(
        `${apiEndpoint}/business/v2/longtermcontract?${queryString}`
      )
      return {
        data: response.data.results,
        allData: response.data.results,
        count: response.data.count,
        totalPages: Math.ceil(response.data.count / queryParams.page_size),
        vehicles: response.data.results
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getLongTermContract = createAsyncThunk(
  'contracts/getLongTermContract',
  async ({ contractId }) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/business/longtermcontract/${contractId}`
      )

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

export const updateOffer = createAsyncThunk(
  'contracts/updateOffer',
  async ({ offerId, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${apiEndpoint}/business/offer/${offerId}`,
        data
      )

      if (response.status === 200) {
        return response.data
      } else {
        // Ako status nije 200, odbacite sa custom vrednošću
        return rejectWithValue({
          errorMessage: 'Something went wrong!',
          status: response.status
        })
      }
    } catch (error) {
      // Uhvatite druge greške (npr. mrežne greške)
      return rejectWithValue({
        errorMessage: 'Something went wrong!',
        status: 0
      })
    }
  }
)

export const generateLongTermContract = createAsyncThunk(
  'contracts/generateLongTermContract',
  async ({ offerId, contractData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/business/offer/${offerId}/generate_contract`,
        contractData
      )

      if (response.status === 200) {
        return response.data
      } else {
        return rejectWithValue({
          errorMessage: 'Something went wrong!',
          status: response.status
        })
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          errorMessage: error.response.data
        })
      }
    }
  }
)

export const updateLongTermContractFile = createAsyncThunk(
  'contracts/updateLongTermContractFile',
  async ({ contractId, file }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/business/longtermcontract/${contractId}/file`,
        file
      )

      if (response.status === 200) {
        return response.data
      } else {
        return rejectWithValue({
          errorMessage: 'Something went wrong!',
          status: response.status
        })
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          errorMessage: error.response.data
        })
      }
    }
  }
)

export const addSignatures = createAsyncThunk(
  'contracts/addSignatures',
  async ({ contractId, data }) => {
    try {
      const response = await axios.put(
        `${apiEndpoint}/business/longtermcontract/${contractId}/signatures`,
        data
      )

      if (response.status === 200) {
        return response.data
      } else {
        return rejectWithValue({
          errorMessage: 'Something went wrong!',
          status: response.status
        })
      }
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          status: error.response.status,
          errorMessage: error.response.data
        })
      }
    }
  }
)

export const updateContract = createAsyncThunk(
  'contracts/updateContract',
  async ({ contractId, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${apiEndpoint}/business/longtermcontract/${contractId}`,
        data
      )

      if (response.status === 200) {
        return response.data
      } else {
        return rejectWithValue({
          errorMessage: 'Something went wrong!',
          status: response.status
        })
      }
    } catch (error) {
      return rejectWithValue({
        errorMessage: 'Something went wrong!',
        status: 0
      })
    }
  }
)

export const addCustomInvoice = createAsyncThunk(
  'contracts/addCustomInvoice',
  async ({ contractId, data }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/business/longtermcontract/${contractId}/custom_sef_invoice`,
        data
      )

      if (response.status === 200 || response.status === 201) {
        return response.data
      } else {
        return rejectWithValue({
          errorMessage: 'Something went wrong!',
          status: response.status
        })
      }
    } catch (error) {
      return rejectWithValue({
        errorMessage: 'Something went wrong!',
        status: 0
      })
    }
  }
)

export const getCustomInvoices = createAsyncThunk(
  'contracts/getCustomInvoices',
  async ({ contractId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/business/longtermcontract/${contractId}/custom_invoice`
      )

      if (response.status === 200) {
        return response.data
      } else {
        return rejectWithValue({
          errorMessage: 'Something went wrong!',
          status: response.status
        })
      }
    } catch (error) {
      return rejectWithValue({
        errorMessage: 'Something went wrong!',
        status: 0
      })
    }
  }
)
export const createDriverAssignment = createAsyncThunk(
  'contracts/createDriverAssignment',
  async (data) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/business/assignment`,
        data
      )
      return response.data
    } catch (error) {
      console.error(err)
    }
  }
)

export const getDriverAssignments = createAsyncThunk(
  'appVehicle/getDriverAssignments',
  async (queryParams = { page_size: 10, page: 1 }) => {
    const queryString = objectToQueryString(queryParams)
    try {
      const response = await axios.get(
        `${apiEndpoint}/business/assignment?${queryString}`
      )
      return {
        data: response.data.results,
        allData: response.data.results,
        count: response.data.count,
        totalPages: Math.ceil(response.data.count / queryParams.page_size),
        vehicles: response.data.results
      }
    } catch (error) {
      console.error(error)
    }
  }
)
export const getDriverAssignmentsByVehicleId = createAsyncThunk(
  'appVehicle/getDriverAssignmentsByVehicleId',
  async (vehicleId) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/business/assignment?vehicle_id=${vehicleId}`
      )
      return {
        data: response.data.results,
        allData: response.data.results,
        count: response.data.count,
        totalPages: response.data.count,
        vehicles: response.data.results
      }
    } catch (error) {
      console.error(error)
    }
  }
)
export const deleteDriversAssignment = createAsyncThunk(
  'formData/deleteDriversAssignment',
  async (id) => {
    try {
      await axios.delete(`${apiEndpoint}/business/assignment/${id}`)
      return id
    } catch (error) {
      console.log(error)
      throw error
    }
  }
)

const contractsSlice = createSlice({
  name: 'contracts',
  initialState: INITIAL_STATE,
  data: [],
  allData: [],
  total: 0,
  params: {},
  count: 0,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getAssignmentsByVehicleId.fulfilled, (state, action) => {
      try {
        state.driverAssignments = action.payload.results || []
      } catch (error) {
        console.log(error)
      }
      state.isLoading = false
    })
      .addCase(getLongTermContractsV2.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getLongTermContractsV2.fulfilled, (state, action) => {
        try {
          state.data = action.payload.data
          state.allData = action.payload.allData
          state.total = action.payload.totalPages
          state.count = action.payload.count
          state.params = action.payload.params
        } catch (error) {
          console.log(error)
        }
        state.isLoading = false
      })
      .addCase(getLongTermContract.fulfilled, (state, action) => {
        try {
          state.contract = action.payload
          state.isLoading = false
        } catch (error) {
          console.log(error)
        } finally {
          state.isLoading = false
        }
      })
      .addCase(getLongTermContract.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateOffer.pending, (state, action) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(updateOffer.fulfilled, (state, action) => {
        try {
          const offerId = action?.payload?.data?.id
          if (!offerId) return
          state.allcontracts = state.allcontracts.map((offer) => {
            return offer.id === offerId ? action.payload.data : offer
          })
        } catch (error) {
          console.log(error)
        } finally {
          state.isLoading = false
        }
      })
      .addCase(updateOffer.rejected, (state, action) => {
        state.error = action.payload
        state.isLoading = false
      })
      // check with BE
      .addCase(updateLongTermContractFile.fulfilled, (state, action) => {
        console.log(action)
        console.log(state)
      })
      .addCase(updateContract.fulfilled, (state, action) => {
        const { legal_representatives, ...restData } = action.payload
        state.contract = { ...state.contract, ...restData }
        state.isLoading = false
      })
      .addCase(updateContract.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateContract.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(addSignatures.fulfilled, (state, action) => {
        state.contract = action.payload
        state.isLoading = false
      })
      .addCase(addSignatures.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addCustomInvoice.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addCustomInvoice.fulfilled, (state, action) => {
        state.isLoading = false
        state.contract.custom_sef_invoices = [
          ...state.contract.custom_sef_invoices,
          action.payload
        ]
      })
      .addCase(addCustomInvoice.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getCustomInvoices.fulfilled, (state, action) => {
        if (state.contract) {
          state.contract.custom_invoices = action.payload
        }
      })
      .addCase(getCustomInvoices.rejected, (state) => {
        state.isLoading = false
        state.contract.custom_invoices = []
      })
      .addCase(getDriverAssignments.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getDriverAssignments.fulfilled, (state, action) => {
        try {
          state.data = action.payload.data
          state.allData = action.payload.allData
          state.total = action.payload.totalPages
          state.count = action.payload.count
          state.params = action.payload.params
          state.isLoading = false
        } catch (error) {
          console.log(error)
        }
        state.isLoading = false
      })
      .addCase(getDriverAssignmentsByVehicleId.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getDriverAssignmentsByVehicleId.fulfilled, (state, action) => {
        try {
          state.allData = action.payload.data
          state.isLoading = false
        } catch (error) {
          console.log(error)
        }
        state.isLoading = false
      })
      .addCase(createDriverAssignment.fulfilled, (state, action) => {
        state.allData = [...state.allData, action.payload]
      })
      .addCase(deleteDriversAssignment.fulfilled, (state, action) => {
        try {
          const filtered = state.allData.filter(
            (driver) => driver.id !== action.payload
          )
          state.allData = [...filtered]
        } catch (error) {
          console.log(error)
        }
      })
  }
})

export default contractsSlice.reducer
