// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../settings'
import { isLastDayOfMonth } from 'date-fns'

export const apiEndpoint = `${BASE_URL}/api`

const authAxios = axios

export const getReminders = createAsyncThunk('appReminders/getReminders', async () => {
    try {
        return await authAxios.get(`${apiEndpoint}/remainder/`)
    } catch (error) {
        console.log(error)
    }
})

export const acknowledgeRemainder = createAsyncThunk(
    'appReminders/acknowledgeRemainder',
    async ({remainderId}) => {
        try {
            const response = await authAxios.post(`${apiEndpoint}/remainder/acknowledge/${remainderId}`)
            return ({
                remainderId
            })
        } catch (error) {
            console.log(error)
        }
})
export const getReminderNotifications = createAsyncThunk('appUsers/getReminderNotifications', async () => {
  try {
    const response = await authAxios.get(`${apiEndpoint}/remainder/notifications`)
    return response.data
  } catch (error) {
    console.log(error)
  }
})
export const appRemindersSlice = createSlice({
    name: 'appReminders',
    initialState: {
        data: [],
        isLoading: false,
        notifications: {}
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getReminders.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getReminders.fulfilled, (state, action) => {
                try {
                    state.data = action.payload.data
                    state.isLoading = false
                } catch (error) {
                    console.log(error)
                }
            })
            .addCase(acknowledgeRemainder.fulfilled, (state, action) => {
                const remainderIdx = state.data.findIndex(r => r.id === action.payload.remainderId)
                state.data[remainderIdx].acknowledge = true
            })
            .addCase(getReminderNotifications.fulfilled, (state, action) => {
                state.notifications = action.payload
            })
    }
})

export default appRemindersSlice.reducer