import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { objectToQueryString } from '../../../../utility/Utils'
// ** Axios Imports
import axios from 'axios'

import { BASE_URL } from '../../../../settings'

export const apiEndpoint = `${BASE_URL}/api`

const authAxios = axios

//Vehicle sale:
export const getVehicleSales = createAsyncThunk(
  'appVehicleSales/getVehicleSales',
  async (queryParams = { page_size: 10, page: 1 }) => {
    const queryString = objectToQueryString(queryParams)
    try {
      const response = await authAxios.get(
        `${apiEndpoint}/business/sale_process?${queryString}&sort_by=id&sort_order=desc`
      )
      return {
        data: response.data.results,
        allData: response.data.results,
        count: response.data.count,
        //totalPages: Math.ceil(response.data.count / queryParams.page_size),
        vehicles: response.data.results
      }
    } catch (error) {
      console.log(error)
    }
  }
)
export const createVehicleSale = createAsyncThunk('appVehicleSales/createVehicleSale', async (data) => {
  try {
    const response = await authAxios.post(`${apiEndpoint}/business/sale_process`, data)
    return response.data
  } catch (error) {
    console.log(error)
  }
})

export const getVehicleSaleById = createAsyncThunk('appVehicleSales/getVehicleSaleById', async (id) => {
  try {
    const response = await authAxios.get(`${apiEndpoint}/business/sale_process/${id}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
})
export const editVehicleSale = createAsyncThunk('appVehicleSales/editVehicleSale', async (data) => {
  try {
    const response = await authAxios.put(`${apiEndpoint}/business/sale_process/${data.id}`, data.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
})
export const forceReopenSale = createAsyncThunk('appVehicleSales/forceReopenSale', async (id) => {
  try {
    const response = await authAxios.post(`${apiEndpoint}/business/sale_process/${id}/force_reopen`, id)
    return response
  } catch (error) {
    console.log(error)
  }
})
export const deleteVehicleSale = createAsyncThunk('appVehicleSales/deleteVehicleSale', async (id) => {
  try {
    const response = await authAxios.delete(`${apiEndpoint}/business/sale_process/${id}`)
    return id
  } catch (error) {
    console.log(error)
    throw error
  }
})

//Sale offer:

export const getSaleOffersById = createAsyncThunk('appVehicleSales/getSaleOfferById', async (id) => {
  try {
    const response = await authAxios.get(`${apiEndpoint}/business/sale_process/${id}/sale_offer`)
    return response.data
  } catch (error) {
    console.log(error)
  }
})
export const createSaleOffer = createAsyncThunk('appVehicleSales/createSellOffer', async (data) => {
  try {
    const response = await authAxios.post(`${apiEndpoint}/business/sale_process/${data.id}/sale_offer`, data.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
})
export const editSaleOffer = createAsyncThunk('appVehicleSales/editSaleOffer', async (data) => {
  try {
    const response = await authAxios.put(`${apiEndpoint}/business/sale_process/${data.id}/sale_offer/${data.offerId}`, data.data)
    return response
  } catch (error) {
    console.log(error)
  }
})
export const deleteSaleOffer = createAsyncThunk('appVehicleSales/deleteSaleOffer', async (data) => {
  try {
    const response = await authAxios.delete(`${apiEndpoint}/business/sale_process/${data.id}/sale_offer/${data.offerId}`)
    return data.offerId
  } catch (error) {
    console.log(error)
  }
})

export const getSaleProcessByVehicle = createAsyncThunk('appVehicleSales/getSaleProcessByVehicle', async (id) => {
  try {
    const response = await authAxios.get(`${apiEndpoint}/business/sale_process/by-vehicle/${id}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
})

export const appVehicleSalesSlice = createSlice({
  name: 'vehicleSales',
  initialState: {
    data: [],
    allData: [],
    total: 1,
    count: 0,
    params: {},
    selectedSale: {},
    saleOffers: [],
    isLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    //Vehicle Sales:
    .addCase(getVehicleSales.rejected, (state, action) => {
      state.isLoading = false
    })
    .addCase(getVehicleSales.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(getVehicleSales.fulfilled, (state, action) => {
      try {
        state.data = action.payload.data
        state.allData = action.payload.allData
        state.total = action.payload.totalPages
        state.count = action.payload.count
        state.params = action.payload.params
      } catch (error) {
        console.log(error)
      }
      state.isLoading = false
    })
    .addCase(deleteVehicleSale.fulfilled, (state, action) => {  
      state.allData = state.allData.filter((item) => item.id !== action.payload)  
    })
    .addCase(getVehicleSaleById.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(getVehicleSaleById.fulfilled, (state, action) => {
      state.selectedSale = action.payload
      state.isLoading = false
    })
    .addCase(editVehicleSale.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(editVehicleSale.fulfilled, (state, action) => {
      state.selectedSale = action.payload
      state.isLoading = false
    })
    //Sale Offer
    .addCase(getSaleOffersById.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(getSaleOffersById.fulfilled, (state, action) => {
      state.saleOffers = action.payload
      state.isLoading = false
    })
    .addCase(createVehicleSale.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(forceReopenSale.fulfilled, (state, action) => {
      state.isLoading = false
    })
    .addCase(createVehicleSale.fulfilled, (state, action) => {
      state.allData.push(action.payload)
      state.isLoading = false
    })
    .addCase(createSaleOffer.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(createSaleOffer.fulfilled, (state, action) => {
      state.saleOffers = [...state.saleOffers, action.payload]
      state.isLoading = false
    })
    .addCase(deleteSaleOffer.fulfilled, (state, action) => {
      state.saleOffers = state.saleOffers.filter((offer) => offer.id !== action.payload)
    })
    .addCase(getSaleProcessByVehicle.fulfilled, (state, action) => {
      state.selectedSale = action.payload
    })
  }
})

export default appVehicleSalesSlice.reducer