// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import configuration from './configuration'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import vehicle from '@src/views/inventory/vehicle/store'
import { partnersApi } from '../views/partners/services/index'
import partners from '../views/partners/store'
import tyres from '@src/views/inventory/tyres/store'
import casco from '@src/views/maintenance/casco/store'
import services from '@src/views/maintenance/services/store'
import damages from '@src/views/maintenance/damages/store'
import penalties from '@src/views/maintenance/penalties/store'
import registrations from '@src/views/maintenance/registrations/store'
import reminders from '@src/views/maintenance/reminders/store'
import finance from '@src/views/finance/store'
import offers from '@src/views/services/store'
import contracts from '@src/views/businesses/store'
import serviceTemplates from '@src/views/maintenance/service-template/store'
import vehicleSales from '@src/views/businesses/vehicle-sales/store'
const rootReducer = {
  auth,
  calendar,
  casco,
  chat,
  configuration,
  contracts,
  damages,
  dataTables,
  ecommerce,
  email,
  finance,
  invoice,
  layout,
  navbar,
  offers,
  partners,
  [partnersApi.reducerPath]: partnersApi.reducer,
  penalties,
  permissions,
  registrations,
  reminders,
  services,
  serviceTemplates,
  todo,
  tyres,
  users,
  vehicle,
  vehicleSales
}

export default rootReducer
