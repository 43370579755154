// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { apiEndpoint } from '../../../inventory/vehicle/store'
import { objectToQueryString } from '../../../../utility/Utils'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async (id) => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk(
  'appUsers/addUser',
  async (user, { dispatch, getState }) => {
    await axios.post('/apps/users/add-user', user)
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())
    return user
  }
)

export const deleteUser = createAsyncThunk(
  'appUsers/deleteUser',
  async (id, { dispatch, getState }) => {
    await axios.delete('/apps/users/delete', { id })
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())
    return id
  }
)

export const createAnalytics = createAsyncThunk(
  'appUsers/createAnalytics',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/reference/analytics`,
        data
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createCoa = createAsyncThunk(
  'appUsers/createCoa',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiEndpoint}/auth/user/coa`, data)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getCoa = createAsyncThunk(
  'appUsers/getCoa',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiEndpoint}/auth/user/coa`, data)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getAnalytics = createAsyncThunk(
  'appUsers/getAnalytics',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/reference/analytics`,
        data
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getClassCoa = createAsyncThunk(
  'appUsers/getClassCoa',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiEndpoint}/manager/coa`, data)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getEuribor = createAsyncThunk(
  'appUsers/getEuribor',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiEndpoint}/reference/euribor`, data)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createEuribor = createAsyncThunk(
  'appUsers/createEuribor',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/reference/euribor`,
        data
      )

      if (response.status === 200) {
        return response.data
      } else {
        return rejectWithValue({
          errorMessage: 'Something went wrong!',
          status: response.status
        })
      }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getExchangeRate = createAsyncThunk(
  'appUsers/getExchangeRate',
  async (queryParams, { rejectWithValue }) => {
    const queryString = objectToQueryString(queryParams)
    try {
      const response = await axios.get(
        `${apiEndpoint}/reference/exchange?${queryString}`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createExchangeRate = createAsyncThunk(
  'appUsers/createExchangeRate',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/reference/exchange`,
        data
      )

      if (response.status === 200) {
        return response.data
      } else {
        return rejectWithValue({
          errorMessage: 'Something went wrong!',
          status: response.status
        })
      }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createUserData = createAsyncThunk(
  'appUsers/createUserData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${apiEndpoint}/auth/user/info`, data)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getUserData = createAsyncThunk(
  'appUsers/getUserData',
  async () => {
    try {
      const response = await axios.get(`${apiEndpoint}/auth/user/info`)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const addUserRepresentative = createAsyncThunk(
  'appUsers/addUserRepresentative',
  async ({ data }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/auth/user/representative`,
        data
      )
      return {
        data: response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
)

export const getUserRepresentatives = createAsyncThunk(
  'appUsers/getUserRepresentatives',
  async () => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/auth/user/representative`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteUserRepresentative = createAsyncThunk(
  'appUsers/deleteUserRepresentative  ',
  async ({ representative_id }) => {
    try {
      await axios.delete(
        `${apiEndpoint}/auth/user/representative/${representative_id}`
      )
      return representative_id
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }
)

export const getCalculatorConfiguration = createAsyncThunk(
  'appUsers/getCalculatorConfiguration',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/auth/user/offer_configuration`
      )
      return {
        data: response.data
      }
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const addCalculatorConfiguration = createAsyncThunk(
  'appUsers/addCalculatorConfiguration',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiEndpoint}/auth/user/offer_configuration`,
        data
      )
      return {
        data: response.data
      }
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    analytics: [],
    classCoa: [],
    exchangeRate: [],
    coaConfigs: [],
    calculatorConfig: {},
    personsInCharge: {
      LEGAL_REPRESENTATIVE: [],
      NONE: [],
      DIRECTOR: [],
      EXECUTIVE_BOARD: [],
      BOARD_OF_DIRECTORS: [],
      PROCURATOR: []
    },
    userData: [],
    bankAccounts: [],
    tenantData: null,
    isLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getAnalytics.fulfilled, (state, action) => {
        state.analytics = action.payload
      })
      .addCase(getCoa.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCoa.fulfilled, (state, action) => {
        state.coaConfigs = action.payload
        state.isLoading = false
      })
      .addCase(getEuribor.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getEuribor.fulfilled, (state, action) => {
        state.euribor = action.payload
        state.isLoading = false
      })
      .addCase(getExchangeRate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getExchangeRate.fulfilled, (state, action) => {
        state.exchangeRate = action.payload
        state.isLoading = false
      })
      .addCase(getClassCoa.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getClassCoa.fulfilled, (state, action) => {
        state.classCoa = action.payload
        state.isLoading = false
      })
      .addCase(getUserRepresentatives.fulfilled, (state, action) => {
        const data = action.payload
        state.personsInCharge = {
          LEGAL_REPRESENTATIVE: [],
          NONE: [],
          DIRECTOR: [],
          EXECUTIVE_BOARD: [],
          BOARD_OF_DIRECTORS: [],
          PROCURATOR: []
        }
        data.forEach((representative) => {
          state.personsInCharge[representative.representative_type]?.push(
            representative
          )
        })
      })
      .addCase(getUserData.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.userData = action.payload.updated_account_information
        state.bankAccounts = action.payload.bank_account_data
        state.tenantData = action.payload.tenant_data
        state.isLoading = false
      })
      .addCase(addUserRepresentative.fulfilled, (state, action) => {
        const newRepresentative = action.payload.data
        const representativeType = newRepresentative.representative_type
        state.personsInCharge[representativeType].push(newRepresentative)
      })
      .addCase(deleteUserRepresentative.fulfilled, (state, action) => {
        const deletedRepresentativeId = action.payload
        Object.keys(state.personsInCharge).forEach((type) => {
          state.personsInCharge[type] = state.personsInCharge[type].filter(
            (representative) => representative.id !== deletedRepresentativeId
          )
        })
      })
      .addCase(getCalculatorConfiguration.fulfilled, (state, action) => {
        state.calculatorConfig = action.payload.data
        state.isLoading = false
      })
      .addCase(getCalculatorConfiguration.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(addCalculatorConfiguration.fulfilled, (state, action) => {
        state.calculatorConfig = action.payload.data
      })
  }
})

export default appUsersSlice.reducer
